import axios from 'axios'

const API_URL = process.env.GATSBY_API_SERVER

class UserService {
  userInfo(id) {
    //retrieve information about an user
    return axios.get(API_URL + `users/${id}`)
  }

  async createUser(userId, name, email, role) {
    //create a new user with a given role
    try {
      const res = await axios.post(API_URL + 'users', {
        userId: userId,
        name: name,
        email: email,
        role: role
      })
      return res.json()
    } catch (err) {
      console.error(err)
      return null
    }
  }

  getUserAffiliateCode(userId) {
    //retrieve an user's affiliate code
    return axios.get(API_URL + `user-affiliatecode/${userId}`)
  }

  getAllAmbassadorsIds() {
    //retrieves all the affiliate id's of the ambassadors
    return axios.get(API_URL + 'all-ambassador-ids')
  }

  async matchEmailWithAffiliateCode(email) {
    //finds the affiliate code given a purchaser's email address
    try {
      const res = await axios.post(API_URL + 'match-affiliate-email', {
        email: email
      })
      return res
    } catch (err) {
      console.error(err)
      return null
    }
  }

  async userIdFromAffiliateCode(affcode) {
    //retrieve the user ID for an affiliate code
    try {
      const res = await axios.post(API_URL + 'retrieve-userid', {
        affcode: affcode
      })
      return res
    } catch (err) {
      console.error(err)
      return null
    }
  }

  async uploadPayloadFileData(data) {
    //uploads the data from the payload calculation file
    try {
      const res = await axios.post(API_URL + 'ambassadors-payload', {
        data: data
      })
      return res
    } catch (err) {
      console.error(err)
    }
  }

  async updateAffiliateAffiliated(email, userId) {
    //updates the affiliate with the email address of the person who signed up under the affiliates' aff code
    try {
      const res = await axios.patch(API_URL + `/affiliates/:${userId}`, {
        email: email
      })
      return res
    } catch (err) {
      console.error(err)
    }
  }

  async subscribeToNotificationList(email, listId) {
    //subscribes an email address to a Klaviyo product notification list
    const options = {
      method: 'POST',
      url: `${process.env.GATSBY_API_SERVER}subscribe-to-notification-list`,
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      data: { email: email, listId: listId }
    }

    await axios
      .request(options)
      .then(function (response) {
        console.log(response.data)
      })
      .catch(function (error) {
        console.error(error)
      })
  }

  getAllKlaviyoLists() {
    //retrieves all the Klaviyo lists
    const options = {
      method: 'GET',
      url: `${process.env.GATSBY_API_SERVER}all-klaviyo-lists`
    }

    return axios.request(options)
  }

  async createNotificationList(name) {
    //create a Klaviyo notification list for a product
    const options = {
      method: 'POST',
      url: `${process.env.GATSBY_API_SERVER}create-klaviyo-list`,
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      data: { name: name }
    }

    await axios
      .request(options)
      .then(function (response) {
        console.log(response.data)
      })
      .catch(function (error) {
        console.error(error)
      })
  }
}

export default new UserService()
